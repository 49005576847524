* {
    box-sizing: border-box;
}

html {
    --color-background: hsl(216, 8%, 12%);
    --color-blue: hsl(208, 100%, 50%);
    --color-gray: hsl(210, 3%, 25%);
    --color-white: white;
    --border-radius: 5px;

    background-color: var(--color-background);
    color: var(--color-white);
}

html,
body,
#root {
    height: 100%;
}

h1,
h2,
h3,
h4,
ul {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    padding: 0;
    list-style: none;
}

button {
    cursor: pointer;
}

input,
button {
    font-size: 1rem;
    color: inherit;
    border: none;
    border-radius: var(--border-radius);
}

.container {
    height: 100%;

    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}
